import { cloneDeep } from "lodash";
import {
  CLEAR,
  DELETE_MODEL,
  REVERT,
  SET_IS_LOADING,
  SET_MODEL,
  SET_UNREGISTER,
  UPDATE_ENTITIES
} from "./mutation-types";
import { setEntities, updateEntities } from "../entities";

export default {
  [CLEAR](state) {
    state.model = cloneDeep(state.emptyModel);
  },
  [SET_MODEL](state, { entities, id }) {
    state.id = id;
    Object.assign(state.model, cloneDeep(entities.entity[id]));
    Object.assign(state.initialModel, cloneDeep(entities.entity[id]));
    setEntities(state, entities);
  },
  [UPDATE_ENTITIES](state, entities) {
    updateEntities(state, entities);
  },
  [SET_IS_LOADING](state, isLoading) {
    state.isLoading = isLoading;
  },
  [SET_UNREGISTER](state, unregister) {
    state.unregister = unregister;
  },
  [REVERT](state) {
    state.model = cloneDeep(state.initialModel);
  },
  [DELETE_MODEL](state) {
    state.model = null;
  }
};
