import Vue from "vue";
import crudMutations from "@/store/templates/crud/mutations";
import { clone, cloneDeep } from "lodash";
import { SET_MODEL } from "../../templates/crud/mutation-types";
import { setEntities } from "../../templates/entities";

export default {
  ...crudMutations,

  [SET_MODEL](state, { entities, id }) {
    const initialModel = cloneDeep(entities.entity[id]);
    // clone enough for localizations, as they only contain string properties. Object.assign({}, target) would work
    // too, but if target is null or undefined this would give an empty object rather than null or undefined.
    initialModel.label = clone(entities.localization[initialModel.label]);
    initialModel.info = clone(entities.localization[initialModel.info]);

    Vue.set(state, "initialModel", initialModel);
    Vue.set(state, "model", cloneDeep(initialModel));

    setEntities(state, entities);
  },

  setName(state, name) {
    state.model.name = name;
  },
  setIsSelectable(state, isSelectable) {
    state.model.isSelectable = isSelectable;
  },
  setLabel(state, label) {
    Vue.set(state.model, "label", label);
  },
  setCustomerLabel(state, customerLabel) {
    Vue.set(state.model, "customerLabel", customerLabel);
  },
  setInfo(state, info) {
    Vue.set(state.model, "info", info);
  }
};
