import initialCrudState from "@/store/templates/crud/state";

const initialModel = () => ({
  id: null,
  name: null,
  isSelectable: null,
  label: null,
  customerLabel: null,
  info: null
});

export default () => ({
  ...initialCrudState(initialModel),
  localization: {}
});
