import crudActions from "@/store/templates/crud/actions";
import api from "@/api";
import { SAVE } from "@/store/templates/crud/action-types";
import {
  SET_IS_LOADING,
  SET_MODEL
} from "@/store/templates/crud/mutation-types";
import { isNil } from "lodash";

export default {
  ...crudActions(api.offerRejectionReason, "offer rejection reason"),
  async [SAVE]({ state, commit }) {
    commit(SET_IS_LOADING, true);
    const { entities, result } = isNil(state.model.id)
      ? await api.offerRejectionReason.create(state.model)
      : await api.offerRejectionReason.update(state.model.id, state.model);
    commit(SET_MODEL, { entities, id: result });
    commit(SET_IS_LOADING, false);
    return result;
  }
};
