import crudGetters from "@/store/templates/crud/getters";

export default {
  ...crudGetters,
  offerRejectionReason: state => state.model,
  id: state => state.model.id,
  name: state => state.model.name,
  label: state => state.model.label,
  customerLabel: state => state.model.customerLabel,
  info: state => state.model.info,
  isSelectable: state => state.model.isSelectable
};
