import tableGetters from "../../../templates/table/getters";

export default {
  ...tableGetters,
  company: state => offerId => state.company[state.entity[offerId].company],
  customer: state => offerId => state.customer[state.entity[offerId].customer],
  lead: state => offerId => state.lead[state.entity[offerId].lead],
  subjects: state => offerId =>
    state.lead[state.entity[offerId].lead].subjects.map(
      subjectId => state.subject[subjectId]
    )
};
